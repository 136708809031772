<template>
  <div>
    <b-alert v-if="error" show variant="danger">The link you have used is not valid.  Please contact technical support.</b-alert>
    <router-view v-else-if="ready" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    ready: false,
    error: null
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn'
    })
  },
  async mounted () {
    try {
      console.log('initialising tenant...')
      await this.$store.dispatch('tenant/initialise', this.$route.params.tenantCode)
    } catch (error) {
      this.error = error
      return
    }

    console.log('initialising authentication...')
    await this.$store.dispatch('auth/initialise')

    if (!this.isLoggedIn && this.$route.meta.requireAuth !== false) {
      this.$router.push({ name: 'login' }, async () => {
        this.ready = true
      })
    } else if (this.isLoggedIn && this.$route.name === 'login') {
      this.$router.push({ name: 'dashboard' }, async () => {
        this.ready = true
      })
    } else {
      this.ready = true
    }
  }
}
</script>
